<template>
	<div class="allbox">
		<div style="padding:10px 0 10px 15px;background:#fff;margin:10px 0 10px 0">
			<el-button type="text" @click="doorPicture">上门自提码</el-button>
		</div>
		<div style="background: #fff;padding: 15px;">
			<!-- tab切换 -->
			<el-tabs v-model="listState" @tab-click="filterFun">
				<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''">
				</el-tab-pane>
			</el-tabs>

			<div v-if="currentTab==0">
				<div class="filter-container">
					<div class="filter-item">
						<label class="label">关键字: </label>
						<el-input v-model="searchKey" @keyup.enter.native="StoreSearch" placeholder="门店名称、编码、地址"
							style="width: 200px;;margin-right: 20px;"></el-input>
						<label class="label">门店标签: </label>
						<el-select v-model="ShopTagId" placeholder="请选择" filterable clearable
							style="width: 160px;margin-right: 10px;">
							<el-option :value="null" label="全部"></el-option>
							<el-option v-for="(item,index) in LabelList" :key="index" :label="item.TagName"
								:value="item.Id">
							</el-option>
						</el-select>
						<label class="label">门店状态: </label>
						<el-select v-model="ShopState" placeholder="请选择" filterable clearable
							style="width: 160px;margin-right: 10px;">
							<el-option :value="null" label="全部"></el-option>
							<el-option v-for="(item,index) in shopStateList" :key="index" :label="item.Name"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="filter-item">
						<el-button type="primary" style="margin-left:30px;width:90px;" size="small"
							@click="StoreSearch">查询</el-button>
						<buttonPermissions :datas="'addstore'">
							<el-button type="primary" style="margin-left:15px;width:90px;" size="small"
								@click="doorAdd({})">添加</el-button>
						</buttonPermissions>
					</div>
				</div>

				<!-- 门店管理表格 -->
				<div class="table-container">
					<el-table :data="StoreListdata" style="width: 100%;" v-loading="loading">
						<el-table-column prop="ShopNo" label="门店编码" :key="0"></el-table-column>
						<el-table-column prop="ShopName" label="门店名称" width="220px" :key="1"></el-table-column>
						<el-table-column label="配送方式" :key="21" width="170px">
							<template slot-scope="scope">
								<div>
									<el-tag type="info" effect="plain" style="margin-right: 5px;margin-bottom: 5px;"
										v-if="scope.row.IsOpenExpress">快递</el-tag>
									<el-tag type="info" effect="plain" style="margin-right: 5px;margin-bottom: 5px;"
										v-if="scope.row.IsOpenSelfExtract">自提</el-tag>
									<el-tag type="info" effect="plain" v-if="scope.row.IsOpenInCityDelivery">同城</el-tag>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="地址" width="250px" prop="Province" :key="2">
							<template slot-scope="scope">
								<div
									style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">
									{{scope.row.Province}}{{scope.row.City}}{{scope.row.Area}}{{scope.row.Address}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="ContactPhone" label="联系电话" :key="3"></el-table-column>
						<el-table-column prop="ShopTagName" label="门店标签" :key="4"></el-table-column>
						<el-table-column prop="BusinessStartTime" label="营业时间" :key="5">
							<template slot-scope="scope">
								<div>{{scope.row.BusinessStartTime}}-{{scope.row.BusinessEndTime}}</div>
							</template>
						</el-table-column>
						<el-table-column label="门店状态">
							<template slot-scope="scope">
								<div>{{scope.row.IsOpen?'正常营业':'停用'}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="AddTime" label="创建时间" :key="6" width="150px"></el-table-column>
						<el-table-column label="操作" width="190" :key="7">
							<template slot-scope="scope">
								<buttonPermissions :datas="'editStore'">
									<el-button type="text" @click="doorAdd(scope.row)">编辑</el-button>
								</buttonPermissions>
								<buttonPermissions :datas="'manageStoreProduct'">
									<el-button type="text" style="margin-left:15px" @click="doorGoodsEdit(scope.row)">
										商品管理</el-button>
								</buttonPermissions>

								<el-dropdown v-if="scope.row.showMoreBtn" @command="(e)=>dropdownChange(e, scope.row)">
									<span class="el-dropdown-link"
										style="color: #409EFF;margin-left: 15px;cursor: pointer;">
										更多<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<buttonPermissions :datas="'createCode'" :Type="1" v-if="scope.row.IsOpen">
											<el-dropdown-item command="create"><span
													style="color: #409EFF;">创建群活码</span></el-dropdown-item>
										</buttonPermissions>
										<buttonPermissions :datas="'blockStore'" :Type="1" v-if="scope.row.IsOpen">
											<el-dropdown-item command="block"><span style="color: #409EFF;">停用</span>
											</el-dropdown-item>
										</buttonPermissions>

										<buttonPermissions :datas="'openStore'" :Type="1" v-if="!scope.row.IsOpen">
											<el-dropdown-item command="open"><span style="color: #409EFF;">启用</span>
											</el-dropdown-item>
										</buttonPermissions>
										<buttonPermissions :datas="'deleteStore'" :Type="1" v-if="!scope.row.IsOpen">
											<el-dropdown-item command="delete"><span style="color: #f56c6c;">删除</span>
											</el-dropdown-item>
										</buttonPermissions>
									</el-dropdown-menu>
								</el-dropdown>

								<!-- <buttonPermissions :datas="'deleteStore'">
									<span style="color:#f00;margin-left:15px;cursor: pointer;"
										@click="deleteChose(scope.row)" v-if='!scope.row.IsOpen'>删除</span>
								</buttonPermissions>
								<buttonPermissions :datas="'createCode'">
									<el-button type="text" @click="createCode(scope.row)" v-if='scope.row.IsOpen'>创建群活码
									</el-button>
								</buttonPermissions>
								<buttonPermissions :datas="'blockStore'">
									<el-button style="margin-left:15px" type="text" @click="blockUp(scope.row)"
										v-if='scope.row.IsOpen'>停用</el-button>
								</buttonPermissions> -->

							</template>
						</el-table-column>
					</el-table>
					<div style="display:flex;justify-content:space-between;align-items:center;">
						<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
						</div>
						<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
							@current-change="handleCurrentChange" :current-page="currentPage"
							:page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="Total">
						</el-pagination>
					</div>
				</div>
			</div>
			<!-- 商品设置 -->
			<div v-else>
				<div class="filter-container">
					<div class="filter-item">
						<div style="display:flex;">
							<div class="label"
								style="width: 70px;text-align: right;white-space: nowrap;font-weight: 700;">关键字:</div>
							<el-select v-model="keywordsType" @change="keywordsTypeChange" placeholder="请选择"
								style="margin-right: 20px;width: 120px;">
								<el-option :key="0" label="单品" :value="0"></el-option>
								<el-option :key="1" label="组合商品" :value="1"></el-option>
							</el-select>
							<el-input v-model="configSearchKey" @keyup.enter.native="goodsFilter" style="width: 200px;"
								placeholder="商品名称、编码、规格条形码"></el-input>
							<div class="label" style="white-space: nowrap;font-weight: 700;margin-left: 20px;">销售类型:
							</div>
							<el-select v-model="saleType" @change="saleTypeChange" placeholder="请选择"
								style="margin-right: 20px;width: 150px;">
								<el-option :key="-1" label="全部" :value="null"></el-option>
								<el-option :key="0" label="单品销售" :value="0"></el-option>
								<el-option :key="1" label="组合销售" :value="1"></el-option>
							</el-select>
							<label class="label" style="white-space: nowrap;">商品分组: </label>
							<el-select v-model="groupId" placeholder="请选择" filterable clearable
								style="margin-right: 20px;width: 150px;">
								<el-option :key="-1" label="全部" :value="null"></el-option>
								<el-option v-for="(item,index) in groupList" :key="index" :label="item.GroupName"
									:value="item.Id">
								</el-option>
							</el-select>
						</div>
						<div style="display: flex;margin-top: 10px;">
							<div class="label" style="width: 70px;white-space: nowrap;font-weight: 700;">商品品牌: </div>
							<el-select v-model="brandId" placeholder="请选择" filterable clearable
								style="margin-right: 20px;width: 150px;">
								<el-option :key="-1" label="全部" :value="null"></el-option>
								<el-option v-for="(item,index) in brandList" :key="index" :label="item.BrandName"
									:value="item.Id">
								</el-option>
							</el-select>
							<div>
								<el-button type="primary" style="width:90px;" size="small" @click="goodsFilter">查询
								</el-button>
							</div>
						</div>
					</div>
				</div>

				<div class="table-container">
					<el-table :data="productList" @sort-change="sortChange" style="width: 100%;" v-loading="loading">
						<el-table-column label="商品" :key="8" width="400px">
							<template slot-scope="scope">
								<div class="product-info">
									<img v-if="scope.row.ImgUrl " :src="imgUrl + scope.row.ImgUrl">
									<svg-icon v-else icon-class="noImgIcon" />
									<div>
										<div style="display:flex;justify-content:space-between;align-items:center;">
											<div
												style="width: 300px;margin-right: 5px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">
												<span style="color: #F56C6C;font-size: 12px;"
													v-if="scope.row.ProductType === 1">[组合]</span>

												<pre
													style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}
												</pre>

											</div>
										</div>
										<div v-if="scope.row.ProductNo">{{scope.row.ProductNo}}</div>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column sortable="custom" prop="Price" label="价格" :key="9">
							<template slot-scope='scope'>
								<div style="color: #F56C6C;">{{scope.row.Price}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="Stock" sortable="custom" label="库存" :key="10">
							<template slot="header" slot-scope="scope">
								<div class="stock-header">
									库存
									<el-tooltip class="item" effect="dark" content="该页面显示库存为商城库存" placement="top-start">
										<i class="el-icon-question"
											style="font-size: 16px;color: #999;margin-left: 5px;"></i>
									</el-tooltip>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="AddTime" label="创建时间" :key="11"></el-table-column>
						<buttonPermissions :datas="'setProductStore'">
							<el-table-column label="操作" width="150px" :key="12">
								<template slot-scope="scope">
									<el-button type="text" style="margin-left:10px" @click="doorSetting(scope.row)">门店设置
									</el-button>
								</template>
							</el-table-column>
						</buttonPermissions>
					</el-table>

					<div style="display:flex;justify-content:space-between;align-items:center;">
						<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
						</div>
						<el-pagination v-if="configTotal" style="margin-top:20px;float:right;"
							@size-change="handleConfigSizeChange" @current-change="handleConfigPageChange"
							:current-page="configPageIndex" :page-sizes="[10, 20, 30, 40, 50]"
							:page-size="configPageSize" layout="total, sizes, prev, pager, next, jumper"
							:total="configTotal">
						</el-pagination>
					</div>
				</div>
			</div>
			<!-- 停用弹框 -->
			<el-dialog :visible.sync="limitVisble" width="550px" title="" v-loading='loading'>
				<div style="line-height:30px;">
					{{blockData.ShopName}}，当前门店相关数据：<br>
					（1）未完成或未关闭的到店自提订单{{limitData.SelfExtractOrderCount||0}}个<br>
					（2）未完成或未关闭的快递发货订单{{limitData.ExpressOrderCount||0}}个<br>
					（3）未完成或未关闭的退款单{{limitData.RefundOrderCount||0}}个<br>
					（4）会员{{limitData.VipCount||0}}人<br>
					（5）员工{{limitData.EmployeeCount||0}}人<br>
					是否确认继续停用该门店？
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button style="width: 120px;" @click="handleLimitEvent(0)">取 消</el-button>
					<el-button style="width: 120px;margin-left: 30px;" type="primary" @click="handleLimitEvent(1)">确 定
					</el-button>
				</span>
			</el-dialog>
			<!-- 推广的弹框 -->

			<el-dialog title="提示" :visible.sync="deleteVisble" width="550px" v-loading='loading'>
				<div style="line-height:30px;padding-left:20px">
					<!-- <div>会员{{limitData.VipCount}}人   活码{{IsHaveCode}}</div> -->
					<!-- 有会员 没有活码 -->
					<div v-if="limitData.VipCount>0 && !IsHaveCode">{{deleteData.ShopName}}存在以下数据:
						<div>1.目前<span style="color: #F56C6C;">仍有会员{{limitData.VipCount}}人,门店删除后，对应会员的归属门店将清空</span>,
						</div>
						<div>您可重新为会员分配归属门店。</div>
						<div>是否确认继续删除门店？</div>
					</div>
					<!-- 有会员且有活码 -->
					<div v-else-if="limitData.VipCount>0 && IsHaveCode">{{deleteData.ShopName}}存在以下数据:
						<div>1.目前<span style="color: #F56C6C;">仍有会员{{limitData.VipCount}}人,门店删除后，对应会员的归属门店将清空</span>,
						</div>
						<div>您可重新为会员分配归属门店。</div>
						<div>2.门店已配置群活码,门店删除后对应<span style="color: #F56C6C;">群活码同步删除</span>。</div>
						<div>是否确认继续删除门店？</div>
					</div>
					<!-- 没有会员没有活码 -->
					<div v-else-if="limitData.VipCount<=0 && !IsHaveCode">删除成功后，不可恢复，是否确认继续删除门店？</div>
					<!-- 没有会员有活码 -->
					<div v-else-if="limitData.VipCount<=0 && IsHaveCode">{{deleteData.ShopName}}存在以下数据:
						<div>1.门店已配置群活码,门店删除后对应<span style="color: #F56C6C;">群活码同步删除</span>。</div>
						<div>是否确认继续删除门店？</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button style="width: 120px;" @click="deleteVisble=false">关 闭</el-button>
					<el-button style="width: 120px;margin-left: 30px;" type="primary" @click="sureDelete">继续删除
					</el-button>
				</span>
			</el-dialog>

		</div>
		<door-code :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel" :Num='number'>
		</door-code>
	</div>
</template>

<script>
	import {
		StoreTagList,
		StoreManageList,
		productsetshopproductconfig,
		shopdelete,
		shopdeletelimitdata,
		shopClose,
		shopCloseLimitData,
		shopOpen
	} from '@/api/TurnTomySelf'
	import {
		shopdeleteishavecode,
		grouplivecodeheadinfo
	} from "@/api/sv1.0.0"
	import {
		productBrandList,
		productGroupList
	} from '@/api/goods.js'
	import buttonPermissions from '@/components/buttonPermissions';
	import doorCode from "@/components/doorCode"
	import config from '@/config/index';

	import {
		mapGetters
	} from 'vuex'

	export default {
		components: {
			buttonPermissions,
			doorCode
		},
		data() {
			return {
				goUrls: config.GO_URL,
				deleteVisble: false,
				blockData: {}, //停用数据
				ShopState: 1,
				shopStateList: [{
					value: 1,
					Name: '正常营业'
				}, {
					value: 0,
					Name: '停用'
				}],
				number: 0,
				weiCodeShow: false,
				weiCodeData: {},
				deleteData: {},
				imgUrl: config.IMG_BASE,
				listStateList: [{
						id: 0,
						value: '门店管理'
					},
					{
						id: 1,
						value: '商品设置'
					}
				],
				listState: '0',
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				StoreListdata: [],
				ShopTagId: null,
				LabelList: [],
				searchKey: '',
				loading: false,
				editVisable: false,
				limitVisble: false,
				groupName: "",
				currentTab: 0, //当前选择的tab
				timeValue: '',
				configPageIndex: 1,
				configPageSize: 20,
				configTotal: 0,
				keywordsType: 0,
				configSearchKey: '',
				saleType: null,
				groupId: null,
				brandId: null,
				orderByType: '',
				orderByIsAsc: null,
				groupList: [],
				brandList: [],
				productList: [],
				limitData: {},
				IsHaveCode: false,
			};
		},
		computed: {
			...mapGetters([
				'routerList'
			])
		},
		watch: {
			routerList() {
				this.showMoreBtn();
			}
		},
		//初始化数据
		mounted() {
			this.ShopTagId = this.$route.query.ShopTagId
			//获取门店标签
			this.getStoreLabel()
			this.getStoreList();

			this.getConfigList();
			this.getGroupList();
			this.getBrandList();

		},
		methods: {
			showMoreBtn() {
				if (!this.routerList) return;
				this.StoreListdata.map(item => {
					if (item.IsOpen) {
						if (this.routerList.indexOf('createCode') > -1 || this.routerList.indexOf('blockStore') > -
							1) {
							item.showMoreBtn = true;
						} else {
							item.showMoreBtn = false;
						}
					} else {
						if (this.routerList.indexOf('openStore') > -1 || this.routerList.indexOf('deleteStore') > -
							1) {
							item.showMoreBtn = true;
						} else {
							item.showMoreBtn = false;
						}
					}
					return item;
				})
				// console.log(this.StoreListdata)
			},
			dropdownChange(name, record) {
				//创建
				if (name == 'create') {
					this.createCode(record);
				}
				//停用
				if (name == 'block') {
					this.blockUp(record);
				}
				//启用
				if (name == 'open') {
					this.openStore(record);
				}
				//删除
				if (name == 'delete') {
					this.deleteChose(record);
				}
			},
			//启用
			async openStore(record) {
				try {
					const res = await shopOpen({
						Id: record.Id
					})
					this.$message.success('操作成功');
					this.getStoreList()
				} catch (e) {
					//TODO handle the exception
				}
			},
			//创建群活码
			createCode(e) {
				this.validHaveCode(res => {
					if (res.IsUseHeadGroupLiveCode) {
						let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
						let url = this.goUrls + headsUrls + '/Customer/storesLiveCode/changeLiveCode?ShopId=' + e
							.Id + '&Id=0'
						window.open(url)
					} else {
						let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
						let url = this.goUrls + headsUrls + '/Customer/storesLiveCode'
						window.open(url)
					}
				});
			},
			async validHaveCode(callback) {
				try {
					const res = await grouplivecodeheadinfo()
					callback && callback(res.Result)
				} catch (e) {
					//TODO handle the exception
				}

			},
			//停用操作
			blockUp(record) {
				this.limitVisble = true;
				this.blockData = record;
				this.getLimitblockData(record.Id);
			},
			async getLimitData(id) {
				try {
					this.loading = true
					let res = await shopdeletelimitdata({
						Id: id
					})
					if (res.IsSuccess) {
						this.limitData = res.Result;
						// console.log(this.limitData, '要删除的信息')
					}
				} catch (err) {

				} finally {
					this.loading = false
				}

			},
			async getLimitblockData(id) {
				try {
					this.loading = true
					let res = await shopCloseLimitData({
						Id: id
					})
					if (res.IsSuccess) {
						this.limitData = res.Result;
						console.log(this.limitData, '要删除的信息')
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			doorPicture() {
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: `pages/order/myOrder/myOrder`,
					Scene: `state=2&type=2`
				}
			},
			// state=2&type=2
			weiCodeCancel(value) {
				this.weiCodeShow = value;
			},
			async getStoreLabel() {
				try {
					let result = await StoreTagList({})
					this.LabelList = result.Result
				} catch (err) {

				}
			},
			//获取门店列表
			async getStoreList() {
				this.loading = true
				try {
					let data = {
						KeyWords: this.searchKey,
						ShopTagId: this.ShopTagId,
						IsOpen: this.ShopState,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await StoreManageList(data)
					if (result.IsSuccess) {
						this.Total = result.Result.Total;
						this.StoreListdata = result.Result.Results.map(item => {
							item.showMoreBtn = false;
							return item;
						});
						// console.log()
						this.showMoreBtn();
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			doorAdd(record) {
				if(record.Id){
					this.$router.push({
						path: '/door/AddStore',
						query: {
							id: record.Id ? record.Id : ''
						}
					})
					return 
				}
				this.$store.dispatch('checkVersion').then(() => {
					this.$router.push({
						path: '/door/AddStore',
						query: {
							id: record.Id ? record.Id : ''
						}
					})
				}).catch((err) => {
					// console.log(err)
				})
				
				
			},
			StoreSearch() {
				this.currentPage = 1
				this.getStoreList()
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getStoreList()
			},
			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getStoreList()
			},
			//顶部tab切换事件
			filterFun(tab, event) {
				if (tab.index == '0') {
					this.currentTab = 0
				} else {
					this.currentTab = 1
				}
			},


			//商品管理
			doorGoodsEdit(record) {
				this.$router.push({
					path: '/door/doorGoodsEdit',
					query: {
						id: record.Id
					}
				})
			},
			//门店设置
			doorSetting(record) {
				this.$router.push({
					path: '/door/doorSetting',
					query: {
						id: record.ProductId
					}
				})
			},
			eidtGroup(item) {
				this.editVisable = true
			},
			deleteChose(record) {
				this.getshopdeleteishavecode(record)
				// this.deleteVisble = true
				// // this.limitVisble = true;
				// this.deleteData = record;
				// this.getLimitData(record.Id);
			},

			async getshopdeleteishavecode(e) {
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					background: 'rgba(39, 37, 37, 0.8);'
				});
				let Id = await e
				try {
					// console.log(Id,'iop')
					let result = await shopdeleteishavecode({
						Id: Id.Id
					})
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.IsHaveCode = result.Result.IsHave
					}
				} finally {
					this.deleteVisble = true
					// this.limitVisble = true;
					this.deleteData = Id;
					this.getLimitData(Id.Id);
					loading.close()
				}
			},

			//确定删除
			async sureDelete(record) {

				let res = await shopdelete({
					Id: this.deleteData.Id
				})
				if (res.IsSuccess) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '删除成功'
					})
					this.deleteVisble = false;
					this.getStoreList()
				}

			},
			//确定停用
			async sureBlock(record) {
				try {
					let res = await shopClose({
						Id: this.blockData.Id
					})
					if (res.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '停用成功'
						})
						this.limitVisble = false;
						this.getStoreList()
					}
				} catch (err) {

				} finally {

				}


			},
			handleLimitEvent(type) {
				if (type === 1) {
					this.sureBlock();
				} else {
					this.limitVisble = false;
				}
			},

			timeChange(time) {
				console.log(time)
				console.log(this.timeValue)
			},

			//商品设置相关
			async getConfigList() {

				this.loading = true;

				const res = await productsetshopproductconfig({
					KeyWordsType: this.keywordsType,
					KeyWords: this.configSearchKey,
					ProductType: this.saleType,
					ProductGroupId: this.groupId,
					ProductBrandId: this.brandId,
					OrderByType: this.orderByType,
					OrderByIsAsc: this.orderByIsAsc,
					Skip: (this.configPageIndex - 1) * this.configPageSize,
					Take: this.configPageSize
				})

				this.loading = false;

				if (res.IsSuccess) {
					this.productList = res.Result.Results;
					this.configTotal = res.Result.Total;
				}

			},
			async getGroupList() {
				const res = await productGroupList();

				if (res.IsSuccess) {
					this.groupList = res.Result;
				}
			},
			async getBrandList() {
				const res = await productBrandList();

				if (res.IsSuccess) {
					this.brandList = res.Result;
				}
			},
			handleConfigSizeChange(val) {
				this.configPageSize = val;
				this.getConfigList();
			},
			handleConfigPageChange(val) {
				this.configPageIndex = val;
				this.getConfigList();
			},
			goodsFilter() {
				this.configPageIndex = 1;
				this.getConfigList();
			},
			keywordsTypeChange(val) {
				console.log(val)
				if (this.configSearchKey) {
					this.saleType = val
				}

				if (this.keywordsType == 0) {
					this.saleType = 0;
				} else {
					this.saleType = 1
				}

			},


			saleTypeChange(val) {
				if (this.configSearchKey) {
					if (val === 0 || val === 1)
						this.keywordsType = val
				}

				if (this.saleType == 0) {
					this.keywordsType = 0
				} else if (this.saleType == 1) {
					this.keywordsType = 1
				}
			},
			sortChange(obj) {

				this.orderByType = obj.prop == 'Stock' ? 1 : 0;
				if (!obj.order) {
					this.orderByIsAsc = null;
				} else {
					this.orderByIsAsc = obj.order === 'ascending';
				}

				this.configPageIndex = 1;
				this.getConfigList();
			}
		}
	}
</script>

<style>
	.stock-header {
		display: inline-flex;
		align-items: center;
	}
</style>
